import { Injectable, Injector } from '@angular/core';
import { LoginDataService } from './login-data.service';
import { filter, take, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CoreServiceBase } from '@core/bases/core-service-base';
import { LocalStorageService } from '@core/core.module';

@Injectable()
export class LoginService extends CoreServiceBase {
  public loginState$ = new BehaviorSubject<boolean>(false);

  constructor(
    injector: Injector,
    private loginData: LoginDataService,
    private router: Router,
    private dialog: MatDialog,
    private storageService: LocalStorageService
  ) {
    super(injector);
  }

  loginBookingWidget(email: string, password: string, rememberMe: boolean, isClinicRegister: boolean = false): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.auth.login(email, password, rememberMe).pipe(
        take(1),
        catchError((error: HttpErrorResponse) => {
          if (error.status !== 400) return throwError(error);
          const message = this.translate.instant('kailo.login.login.bad-request-message');
          this.notify.error(message);
          observer.next(false);
        })
      ).subscribe(() => {
        this.session.updateLanguage(this.storageService.getItem('preferredLanguage'));
        this.storage.removeItem('isRegister');
      });
  
      combineLatest([
        this.auth.isAuthenticated$,
        this.auth.hasUserAcceptedTermsConditions$
      ]).pipe(
        filter(([isAuthenticated]) => isAuthenticated),
        take(1)
      ).subscribe(([isAuthenticated]) => {
        if (isAuthenticated) {
          this.loginState$.next(isAuthenticated);
          observer.next(isAuthenticated);
        }
      });
    });
  }
  
  login(email: string, password: string, rememberMe: boolean, isClinicRegister: boolean = false) {
    this.auth.login(email, password, rememberMe).pipe(
      take(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 400) return throwError(error);

        const message = this.translate.instant('kailo.login.login.bad-request-message');
        this.notify.error(message);
      })
    ).subscribe(()=>{
      this.session.updateLanguage(this.storageService.getItem('preferredLanguage'));
      this.storage.removeItem('isRegister');
    });

    combineLatest([
      this.auth.isAuthenticated$,
      this.auth.hasUserAcceptedTermsConditions$
    ]).pipe(
      filter(([isAuthenticated]) => isAuthenticated),
      take(1)
    ).subscribe(([isAuthenticated, hasUserAcceptedTermsConditions]) => {
      if (isClinicRegister) {
        this.router.navigate(['register/clinic/information',{email:btoa(email)}]);
      }
      else{
      if (hasUserAcceptedTermsConditions) {
        this.router.navigate(['']);
      } else {
        this.router.navigate(['/terms'],{ queryParams: { codeType: btoa('BOOKINGS'), codeEntity: btoa('PATIENT') }});
      }
    }
    });
  }

  forgotPassword(email: string , language: string) {
    this.loginData.forgotPassword(email, language).subscribe(() => {
      const message = this.translate.instant('kailo.login.forgot-password.success');
      this.notify.success(message);
    }, error => {
      const errorMessage = error?.errorBag?.errorBox && error?.errorBag?.errorBox[0] ? error?.errorBag?.errorBox[0] : 'An error occurred';
      this.notify.error(errorMessage);
    });
  }

  authorizeSocialUser(url: string, idToken: string, email: string, fullName: string, provider:string): any{
    debugger;
    return this.auth.authorizeSocialUser(url, idToken, email, fullName, provider)
   }
}
 