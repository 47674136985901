import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading, PreloadAllModules } from '@angular/router';
import { ValidGuard } from '@core/guards/valid-guard';
import { AuthGuard } from '@core/core.module';
import { HomeGuard } from '@core/guards/home.guard';
import { environment } from 'environments/environment';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Permissions } from '@core/helpers/all-permissions';
import { CommonModule } from '@angular/common';
import { VideoFeatureGuard } from '@core/guards/video-feature.guard';
import { SelfRegisterGuard } from '@core/guards/self-register.guard';
import { TermsAndConditionsGuard } from '@core/guards/terms-and-conditions.guard';
import { AssignmentsGuard } from '@core/guards/assignments.guard';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, ValidGuard, HomeGuard],
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'terms',
    canActivate: [TermsAndConditionsGuard],
    loadChildren: () => import('./features/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
  },
  {
    path: 'account',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'bookings',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/bookings/bookings.module').then(m => m.BookingsModule)
  },
  {
    path: 'calendar',
    canActivate: [AuthGuard, ValidGuard],    
    loadChildren: () => import('./features/calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: 'cases',
    canActivate: [AuthGuard, ValidGuard],   
    loadChildren: () => import('./features/cases/cases.module').then(m => m.CasesModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard, ValidGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: Permissions.Users.Read,
          redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'search',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'pastbookings',
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: Permissions.PastBookings.Read,
          redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/past-bookings/past-bookings.module').then(m => m.PastBookingsModule)
  },
  {
    path: 'assignments',
    canActivate:  [AuthGuard, ValidGuard, NgxPermissionsGuard, AssignmentsGuard],
    data: {
      permissions: {
        only: Permissions.Assignments.Read,
          redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/assignments/assignments.module').then(m => m.AssignmentsModule)
  },
  {
    path: 'video-chat',
    canActivate: [AuthGuard, ValidGuard, VideoFeatureGuard, NgxPermissionsGuard ],
    data: {
      permissions: {
        only: Permissions.JoinVideoCall.Read,
          redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/video-chat/video-chat.module').then(m => m.VideoChatModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard, ValidGuard],
    loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'companies',
    canActivate: [AuthGuard, ValidGuard,NgxPermissionsGuard],
    data: {
      permissions: {
        only: Permissions.Companies.Read,
        redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/companies/companies.module').then(m => m.CompaniesModule)
  },
  {
    path: 'treatmentproviders',
    canActivate:  [AuthGuard,NgxPermissionsGuard],
    data: {
      permissions: {
        only: Permissions.TreatmentProviders.Read,
          redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/treatment-providers/treatment-providers.module').then(m => m.TreatmentProvidersModule)
  },
  {
    path: 'portalnotifications',
    canActivate:  [AuthGuard,NgxPermissionsGuard],
    data: {
      permissions: {
        only: Permissions.PortalNotifications.Read,
          redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/portal-notifications/portal-notifications.module').then(m => m.PortalNotificationsModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard, ValidGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: Permissions.EmailTemplates.Read,
        redirectTo: {
          default: ''
        }
      }
    },
    loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'clinic',
    loadChildren: () => import('./features/clinic-profile/clinic-profile.module').then(m => m.ClinicProfileModule),
  },
  {
    path: 'clinic-search',
    canActivate:  [AuthGuard],
    loadChildren: () => import('./features/clinic-search/clinic-search.module').then(m => m.ClinicSearchModule),
  },
  {
    path: 'booking-widget',
    loadChildren: () => import('./features/booking-widget/booking-widget.module').then(m => m.BookingWidgetModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./features/self-register-select/self-register-select.module').then(m => m.SelfRegisterSelectModule),
    canActivate: [SelfRegisterGuard]
  },
  {
    path: 'mitidcallback',
    loadChildren: () => import('./features/mitid/mitid.module').then(m => m.MitidModule),
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: environment.production ? NoPreloading : PreloadAllModules,
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { } 