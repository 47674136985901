import { Moment } from 'moment';
import * as moment from 'moment';

export function mapDateOfBirthToAge(dateOfBirth: Moment): number {
  return moment().diff(dateOfBirth, 'years');
}

export function combineMomentDateAndTimeSpan(date: Moment, time: string): Moment {
  const split = time.split(':'); 
  /* Temporary fix for 31.09.2021 */
  if(date.toDate().getDate() === 31 && date.toDate().getMonth() === 9){
    split[0]= (Number(split[0]) + 1).toString();
  }
 
  const value = date.clone().startOf('day').add({  
    hours: +split[0],
    minutes: +split[1]
  });

  return value;
}

export function isNullOrWhitespace(input) {
  return !input || !input.trim();
}