import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ResetPasswordModel, ForgotPasswordClient, ForgotPasswordModel,
  ResultDTO, IframeRememberToken, NemIdLoginClient,
  ResponseContent, SaveUserCPRFromPIDModel
} from '@shared/http-clients/http-clients';
import { take, finalize, tap } from 'rxjs/operators';

export const LoginBackgroundImage = 'login-background.png';

@Injectable()
export class LoginDataService {
  private _isResettingPassword$ = new BehaviorSubject<boolean>(false);
  readonly isResettingPassword$ = this._isResettingPassword$.asObservable();

  private _isLoadingForgotPassword$ = new BehaviorSubject<boolean>(false);
  readonly isLoadingForgotPassword$ = this._isLoadingForgotPassword$.asObservable();

  private _isGeneratingNemIdParameters$ = new BehaviorSubject<boolean>(false);
  readonly isGeneratingNemIdParameters$ = this._isGeneratingNemIdParameters$.asObservable();

  private _isLoadingResponseData$ = new BehaviorSubject<boolean>(false);
  readonly isLoadingResponseData$ = this._isLoadingResponseData$.asObservable();

  private _responseData$ = new BehaviorSubject<any>(undefined);
  readonly responseData$ = this._responseData$.asObservable();

  private _isSavingCprFromPID$ = new BehaviorSubject<boolean>(false);
  readonly isSavingCprFromPID$ = this._isSavingCprFromPID$.asObservable();

  private _savingCprFromPidResult$ = new BehaviorSubject<any>(undefined);
  readonly savingCprFromPidResult$ = this._savingCprFromPidResult$.asObservable();

  constructor(
    private forgotPasswordClient: ForgotPasswordClient,
    private nemIdClient: NemIdLoginClient
  ) { }

  forgotPassword(email: string , language: string): Observable<void> {
    this._isLoadingForgotPassword$.next(true);
    const model = new ForgotPasswordModel({ email: email , language: language });

    return this.forgotPasswordClient.post(model).pipe(
      take(1),
      finalize(() => this._isLoadingForgotPassword$.next(false))
    );
  }

  resetPassword(model: ResetPasswordModel): Observable<ResultDTO> {
    this._isResettingPassword$.next(true);

    return this.forgotPasswordClient.patch(model).pipe(
      take(1),
      finalize(() => this._isResettingPassword$.next(false))
    );
  }

  generateParameters(model: IframeRememberToken): Observable<any> {
    this._isGeneratingNemIdParameters$.next(true);
    return this.nemIdClient.loginParameters(model).pipe(
      take(1),
      finalize(() => this._isGeneratingNemIdParameters$.next(false))
    );
  }

  checkResponse(responseContent: ResponseContent): Observable<any> {
    this._isLoadingResponseData$.next(true);
    return this.nemIdClient.checkResponse(responseContent).pipe(
      take(1),
      tap(response => this._responseData$.next(response)),
      finalize(() => this._isLoadingResponseData$.next(false))
    );
  }

  saveUserCPRFromPID(model: SaveUserCPRFromPIDModel) {
    this._isSavingCprFromPID$.next(true);
    return this.nemIdClient.saveUserCPRFromPID(model).pipe(
      take(1),
      finalize(() => this._isSavingCprFromPID$.next(false))
    );
  }

}
